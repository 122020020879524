<template>
  <LayoutVerticalCore>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <Navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

  </LayoutVerticalCore>
</template>

<script>
// import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
// import Navbar from '../components/Navbar.vue';

export default {
  name: 'LayoutVertical',

  components: {
    // LayoutVertical,
    // Navbar,
    LayoutVerticalCore: () => import('@core/layouts/layout-vertical/LayoutVerticalCore.vue' /* webpackChunkName: "LayoutVerticalCore" */),
    Navbar: () => import('@/layouts/components/Navbar.vue' /* webpackChunkName: "Navbar" */),
  }
};
</script>
