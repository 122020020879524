<template>
  <b-spinner v-if="isLoading" class="d-block mx-auto text-primary my-2" />
    <layout-vertical v-else>
      <router-view />
    </layout-vertical>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
//import { NOTIFICATIONS_ACTIONS } from '@/store/notifications/notifications-store-constants';
import FaviconPlaceholder from '@/assets/images/placeholders/light/nectios-favicon.png';
//import Service from '@/config/service-identifiers';
import { selectLangFromCommunity } from '@/@core/utils/collective';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { startNotifications, sendMixpanelNotification } from '@/@core/utils/notifications/notifications';
import LayoutVertical from '@/layouts/vertical/LayoutVertical.vue';
//import { Crisp } from "crisp-sdk-web";
// import { isUserLoggedIn } from '@/auth/utils';

import { mapGetters } from 'vuex'

export default {
  name: 'BackofficeRoot',

  components: {
    LayoutVertical
  },

  data() {
    return {
      isLoading: true,
      user: {},
    };
  },
  computed: {
    ...mapGetters([
      'loggedUser', 
      'loggedMember',
      'mainCollective',
      'currentCollective'
    ]),
    
    collectiveSlug() {
      return this.$route.params.communityId;
    },
    currentCollectiveSlug() {
      return this.$store.getters.currentCollective
        ? this.$store.getters.currentCollective.slug
        : null;
    },
    documentTitle() {
      if (typeof this.$store.getters.currentCollective.name === 'string') {
        return this.$store.getters.currentCollective.name;
      }
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.$store.getters.currentCollective.name);
    },
    faviconURL() {
      return (
        getImageResource(this.$store.getters.collectiveFavicon)
        || FaviconPlaceholder
      );
    },
    mainSpace() {
      return this.currentCollective?.parentKey ? this.mainCollective : this.currentCollective;
    }
  },
  watch: {
    async collectiveSlug(collectiveSlug) {
      if (collectiveSlug !== this.currentCollectiveSlug) {
        this.initCommunity(collectiveSlug);
      }
    },
  },

  async created() {
    //console.log('BACKOFFICE ROOT')
    this.isLoading = true;
    // Start Application
    // //('this.loggedMember', this.loggedMember);
    // if(!this.loggedMember.id) {
    //   this.$store.dispatch('fetchLoggedUser2', {
    //     communitySlug: this.collectiveSlug
    //   });
    // }

    //console.log('communityinitialized', this.$store.getters.communityinitialized);
    if(!this.$store.getters.backofficeinitialized){
      await this.initBackoffice(this.collectiveSlug);
    }

    await startNotifications({ 
      memberKey: this.loggedMember.key,
      communitySlug: this.collectiveSlug
    });

    await sendMixpanelNotification('staff-in-backoffice');

    this.isLoading = false;

    this.$router.afterEach((to, from) => {
      this.$store.dispatch('addAnalytic', {
        morphType: 'page',
        action: 'enter-page',
        content: to.path,
      });
      if (this.$store.getters.currentCollective) {
        selectLangFromCommunity(this.$store.getters.currentCollective);
      }
    });

    this.$store.dispatch('addAnalytic', {
      morphType: 'page',
      action: 'enter-page',
      content: this.$route.path,
    });

    //console.log('BACKOFFICE ROOT FINAL')
  },

  mounted() {
    if (!this.$store.state.copernicData.mountedSocket[this.collectiveSlug]) {
      this.$socket.emit('joinAnalytics', this.collectiveSlug);
      this.$socket.emit('joinCommunity', this.collectiveSlug);
      this.$store.state.copernicData.mountedSocket[this.collectiveSlug] = true;
    }
    if (!this.$store.state.copernicData.mountedSocket.global) {
      this.Notifications();
      this.$store.state.copernicData.mountedSocket.global = true;
    }
  },
  sockets: {

  },
  methods: {
    Notifications() {
      // TODO...
    },
    async initBackoffice(collectiveSlug) {
      await this.$store.dispatch('initCurrentBackoffice', {
        communitySlug: collectiveSlug,
      });

      selectLangFromCommunity(this.$store.getters.currentCollective);

      // Page title
      document.title = this.translate('Backoffice | ' + this.documentTitle);

      //Add favicon
      const links = document.querySelectorAll("link[rel~='icon']");
      for (let index = 0; index < links.length; index += 1) {
        const link = links[index];
        link.href = this.faviconURL;
      }

      // Add Nectios chatbot
      console.log('this.mainSpace', this.mainSpace);
      // if(this.mainSpace.level >= 2){
      //   const chatBot = document.createElement('script');
      //   chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
      //   document.body.appendChild(chatBot);
      // }

    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },

  },
};
</script>
